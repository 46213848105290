.disclaimerH4 {
  font-size: 0.8125rem;
  font-weight: 700;
  margin: 0;
}

.disclaimerContactLink {
  text-decoration: none;
  color: var(--footer-fontColor);
}

.disclaimerParagraph {
  margin: 1rem 0;
}
