@use "../../styles/constants/devices" as *;

.stateSelectorContainer {
  &--footer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h4 {
      margin-bottom: 0;
    }
  }
}

.stateSelectorIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  align-items: center;
  position: relative;
  background: var(--header-moreMenuIconsBackground);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;

  @media only screen and (min-width: $smallMobile) {
    margin-right: 16px;
  }

  @media only screen and (min-width: $tablet) {
    width: 40px;
    height: 40px;
  }

  span {
    color: var(--stateSelector-primaryFontColor);
    font-family: var(--font-montserrat);
    font-weight: 900;
    text-transform: uppercase;
  }

  g {
    fill: var(--header-fontColor);
  }

  &--footer {
    flex-direction: unset;
    justify-content: space-between;
    height: 40px;
    width: 176px;

    font-family: Montserrat, sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--stateSelector-primaryFontColor);
    border: 1px solid var(--stateSelector-primaryFontColor);
    border-radius: 4px;
    padding: 8px 12px;
    margin-top: 16px;

    span {
      color: var(--stateSelector-primaryFontColor);
      font-family: var(--font-montserrat);
      font-weight: 700;
      text-transform: unset;
    }

    g {
      color: var(--stateSelector-primaryFontColor);
    }

    @media only screen and (min-width: $tablet) {
      height: 40px;
      width: 176px;
    }
  }
}

.stateSelectorDropdown {
  position: absolute;
  top: 67%;
  right: 2%;
  z-index: 1;
  color: var(--stateSelector-secondaryFontColor);
  border-radius: 8px;
  box-shadow: var(--stateSelector-dropdownShadowColor) 0 0 10px;
  width: 90vw;

  @media only screen and (min-width: $mobile) {
    width: 40vw;
    right: 10%;
  }

  &--footer {
    top: 100%;
    right: unset;
    width: 90vw;
    margin-top: 1rem;

    @media only screen and (min-width: $mobile) {
      width: 40vw;
      right: unset;
    }
  }
}

.stateSelectorDropdownHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--stateSelector-dropdownHeaderColor);
  color: var(--stateSelector-primaryFontColor);
  font-family: var(--font-montserrat);
  font-weight: 800;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.98px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0 0 0 16px;

  button {
    margin-right: 0;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 44px;
    height: 44px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--stateSelector-primaryFontColor);
    }
  }
}

.stateSelectorScrollContainer {
  overflow-y: scroll;
  max-height: 400px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--stateSelector-primaryFontColor);

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: var(--stateSelector-scrollBarColor);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stateSelector-scrollBarColor);
    border-radius: 8px;
    border: 1px solid var(--stateSelector-scrollBarBorderColor);
  }

  h3 {
    font-family: var(--font-montserrat);
    font-weight: 800;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.84px;
    padding: 16px;
    border-bottom: 1px solid var(--stateSelector-headerBorderBottomColor);
    margin: 0;
  }

  @media only screen and (min-width: $mobile) {
    max-height: 600px;
  }

  &--footer {
    max-height: 230px;
  }
}
