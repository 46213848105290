.stateList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
  list-style-type: none;
  padding: 16px 16px 0;
  margin: 0;
  font-size: 0.875rem;
  background: var(--stateSelector-primaryFontColor);

  li {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    margin-bottom: 16px;
    width: 110px;
    white-space: nowrap;
  }
}

.activeState {
  font-weight: 700;

  img {
    margin-left: 8px;
  }
}
