@use "@/styles/constants/devices.scss" as *;

.footerH4 {
  font-size: 0.8125rem;
  font-weight: 700;
  margin: 0;
  a {
    text-decoration: none;
    color: var(--footer-fontColor);
  }
}

.footerTipUl {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 5px 35px;
  font-size: 0.8125rem;
  font-weight: 700;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 9px 0 0 0;
  @media only screen and (min-width: $tablet) {
    grid-template-columns: repeat(3, auto);
  }
}

.footerTipLi {
  padding: 8px 0;

  a {
    text-decoration: none;
    color: var(--footer-fontColor);
  }
}
