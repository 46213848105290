.toggleHeading {
  margin: 8px 0;
}

.toggleSwitchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  span {
    margin: 0;
  }
}

.toggleSwitch {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
  &:checked + label {
    background: var(--accent);
  }

  &:checked + label:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
}

.toggleSwitchLabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 36px;
  background: grey;
  display: block;
  border-radius: 40px;
  position: relative;
  margin: 0 5px;
  &:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 40px;
    transition: 0.3s width ease-in-out;
  }
  &:active:after {
    width: 75%;
  }

  transform: scale(0.75);
}
