.banner {
  position: sticky;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: var(--banner-backgroundColor);

  img {
    position: unset !important;
    width: 100%;
  }

  a {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
