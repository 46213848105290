$fa: (
  background: map-get($faColors, faLightModeBackground),
  contentBackground: $pureWhite,
  subText: map-get($cools, 700),
  contentHeaderFontColor: $pureWhite,
  contentHeaderBackground: map-get($faColors, faDarkBlue),
  defaultText: $pureBlack,
  timeToThink: $timeToThink,
  accent: map-get($faColors, faYellow),
  activeElement: map-get($cools, 900),
  inactiveElement: map-get($cools, 200),
  formResultMetaFontColor: map-get($cools, 800),
  darkerBorder: map-get($cools, 200),
  border: map-get($cools, 100),
  cardWrapper: (
    viewAllIconBackgroundColor: map-get($faColors, faLightBlue),
    competitionHeaderBackgroundColor:
      map-get($cardWrapper, lightCompetitionHeaderBackgroundColor),
    highlight: map-get($cardWrapper, highlightColor),
  ),
  betslip: (
    background: map-get($faColors, midBlue),
    defaultText: $pureWhite,
    highlight: $pureWhite,
    secondary: map-get($cools, 800),
    clearButtonFontColor: map-get($faColors, paleBlue),
    borderBottomColor: map-get($faColors, faDarkBlue),
    disabledButtonBackgroundColor: map-get($results, loser),
    enabledButtonBackgroundColor: map-get($results, winner),
    selectionBorderBottomColor: map-get($pureWhiteGradients, 100),
    clearAllButtonBackgroundColor: $pureWhite,
    clearAllButtonFontColor: map-get($faColors, faDarkBlue),
    labelPrimaryColor: map-get($betSlipLabels, defaultPrimary),
    labelBackgroundColor: map-get($betSlipLabels, defaultBackground),
    fastBetLabelPrimaryColor: map-get($betSlipLabels, fastBetPrimary),
    fastBetLabelBackgroundColor: map-get($betSlipLabels, fastBetBackground),
    betStakeBorder: 1px dashed var(--betslip-highlight),
  ),
  card: (
    contentHover: map-get($pureBlackGradients, 200),
  ),
  bookmakerPortal: (
    buttonEmptyBackgroundColor: map-get($faColors, faDarkBlue),
    buttonFillBackgroundColor: $pureWhite,
    subHeaderBorder: map-get($pureWhiteGradients, 100),
    darkBorder: map-get($faColors, lightMidBlue),
    lightBorder: $pureWhite,
    darkText: $pureBlack,
    fadedText: map-get($faColors, paleBlue),
    defaultText: $pureWhite,
    alertBackground: $pureWhite,
  ),
  buttonPill: (
    activeBackgroundColor: map-get($faColors, faYellow),
    activeFontColor: map-get($cools, 700),
    inactiveBackgroundColor: map-get($faColors, lightMidBlue),
    inactiveFontColor: $pureWhite,
    borderColor: $lightBorderColor,
    toggleFontColor: $pureBlack,
  ),
  ctaButton: (
    fontColor: map-get($ctaButton, fontColor),
    backgroundColor: map-get($ctaButton, backgroundColor),
    fontColorSecondary: map-get($ctaButton, fontColorSecondary),
    backgroundColorSecondary: map-get($ctaButton, backgroundColorSecondary),
  ),
  editor: (
    fontColour: $pureBlack,
    sporcle: "none",
    blockquoteBorder: map-get($faColors, faYellow),
  ),
  footer: (
    fontColor: $pureBlack,
    backgroundColor: map-get($faColors, faLightModeBackground),
    navBackgroundColor: map-get($faColors, faLightModeBackground),
  ),
  oddsFormatPicker: (
    fontColor: $pureBlack,
    activeFontColor: $pureWhite,
    activeBackgroundColor: $pureBlack,
    border: $pureBlack,
  ),
  leagueTable: (
    winnerBorderColor: map-get($results, winner),
    playoffBorderColor: map-get($results, draw),
    loserBorderColor: map-get($results, loser),
    highlightedRowColor: map-get($pures, 100),
    borderColor: map-get($pures, 100),
    primaryFontColor: $pureBlack,
    secondaryFontColor: map-get($pures, 600),
  ),
  header: (
    backgroundColor: map-get($faColors, faYellow),
    secondaryColor: map-get($cools, 900),
    activeSecondaryColor: map-get($faColors, faDarkBlue),
    fontColor: map-get($faColors, faDarkBlue),
    moreMenuBackground: map-get($faColors, faDarkBlue),
    moreMenuBoxShadow: map-get($pureBlackGradients, 400),
    moreMenuIconsBorder: map-get($pureBlackGradients, 200),
    moreMenuIconsBackground: map-get($faColors, faDarkBlue),
    moreMenuFontColor: $pureWhite,
  ),
  matchOverviewInsights: (
    backgroundColor: map-get($faColors, lightPaleBlue),
    borderColor: map-get($faColors, darkPaleBlue),
    separator: map-get($faColors, lightPaleBlue),
    buttonSeparator: $pureBlack,
  ),
  mobileNav: (
    backgroundColor: map-get($cools, 900),
    menuHeadingColor: map-get($faColors, faDarkBlue),
    fontColor: $pureWhite,
    menuBorderColor: map-get($cools, 800),
    menuIconFill: map-get($faColors, faDarkBlue),
    menuIconsBorder: map-get($pureBlackGradients, 200),
    navButtonBorderRadius: 0,
    navButtonBackgroundColor: "transparent",
  ),
  secondaryNav: (
    background: map-get($faColors, faDarkBlue),
    desktopLinkBackground: map-get($faColors, faLightBlue),
    linkBackgroundColor: map-get($faColors, faYellow),
    linkHoverText: $pureBlack,
    activeLinkColor: map-get($faColors, faDarkBlue),
  ),
  notifications: (
    backgroundColor: map-get($notifications, backgroundColor),
    fontColor: map-get($notifications, fontColor),
    sideMenuBackgroundColor: map-get($notifications, backgroundColor),
    sideMenuFontColor: $pureBlack,
  ),
  dataTable: (
    tableHeaderColor: map-get($pures, 100),
    secondaryFontColor: $pureBlack,
    evenRowsColor: $pureWhite,
  ),
  pagination: (
    activeBackgroundColor: map-get($faColors, lightMidBlue),
    activeFontColor: $pureWhite,
  ),
  tipsterBetBuilder: (
    backgroundColor: map-get($tipster, backgroundColor),
    borderColor: map-get($tipster, borderColor),
    secondaryBorderColor: map-get($tipster, secondaryColor),
    activeButtonColor: map-get($tipster, activeButtonColor),
    activeButtonFontColor: $pureWhite,
    buttonFontColor: map-get($tipster, secondaryColor),
  ),
  matchOverview: (
    overlay: map-get($pureWhiteGradients, 800),
    scoreBackground: $pureWhite,
    scoreText: $pureBlack,
    competitionFontColor: $pureBlack,
    winnerSolidColor: map-get($results, winner),
    winnerFadedColor: map-get($results, winnerFaded),
    drawSolidColor: map-get($results, draw),
    drawFadedColor: map-get($results, drawFaded),
    loserSolidColor: map-get($results, loser),
    loserFadedColor: map-get($results, loserFaded),
  ),
  modal: (
    overlayBackgroundColor: map-get($pureBlackGradients, 600),
    allOffersButtonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
    headerBackgroundColor: map-get($faColors, midBlue),
    containerBackgroundColor: $pureWhite,
    primaryTextColor: $pureWhite,
    allOffersButtonBackgroundColor:
      map-get($modal, allOffersButtonBackgroundColor),
  ),
  interstitial: (
    containerBackgroundColor: map-get($pures, 200),
    featuredContainerBackgroundColor: map-get($faColors, lightMidBlue),
    featuredContainerFontColor: $pureWhite,
    contentFontColor: $pureBlack,
    buttonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
  ),
  statsTipster: (
    borderColor: map-get($tipster, borderColor),
    secondaryBorderColor: map-get($tipster, borderColor),
    dashedBorderColor: map-get($tipster, borderColor),
    headerBackgroundColor: map-get($faColors, faDarkBlue),
    headerFontColor: $pureWhite,
    subText: map-get($pures, 500),
    statsButtonBackground: map-get($faColors, midBlue),
    statsButtonColor: $pureWhite,
    activeStatsBorderColor: map-get($faColors, midBlue),
    statsIconColor: map-get($faColors, paleBlue),
    activeStatsButtonBackground: map-get($tipster, borderColor),
    activeStatsButtonColor: $pureBlack,
    activeStatsIconColor: map-get($faColors, faYellow),
    reasonBorderColor: map-get($tipster, reasonBorderColor),
    reasonBackgroundColor: map-get($tipster, reasonBackgroundColor),
    reasonMarketColor: $pureBlack,
    teamSelector: map-get($faColors, faYellow),
    tableFontColor: map-get($faColors, lightMidBlue),
    inactiveTabFontColor: map-get($faColors, paleBlue),
  ),
  betType: (
    defaultText: $pureWhite,
    backgroundColor: map-get($faColors, midBlue),
  ),
  tab: (
    defaultText: $pureBlack,
    activeText: $pureWhite,
    underlineColor: $pureWhite,
    inactiveTabColor: map-get($cools, 100),
    activeTabColor: map-get($faColors, faDarkBlue),
    activeTabUnderlineColor: map-get($faColors, faYellow),
    tabContainerBackgroundColor: $pureWhite,
  ),
  otherTips: (
    borderColor: map-get($otherTips, borderColor),
  ),
  smartAccaMatch: (
    primaryBackgroundColor: map-get($faColors, midBlue),
    secondaryBackgroundColor: map-get($faColors, faDarkBlue),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($faColors, paleBlue),
    highProbabilityFontColor: map-get($faColors, smartAccaGreen),
    buttonFontColor: $pureBlack,
    buttonBackgroundColor: $pureWhite,
    activeButtonBackgroundColor: map-get($faColors, faYellow),
    scrollBarColor: map-get($faColors, midBlue),
    scrollBarBackgroundColor: map-get($faColors, faDarkBlue),
    borderColor: map-get($faColors, faLightBlue),
  ),
  suggestedSmartAcca: (
    primaryBackgroundColor: map-get($faColors, midBlue),
    secondaryBackgroundColor: map-get($faColors, faDarkBlue),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($faColors, paleBlue),
    buttonBackgroundColor: $pureWhite,
    buttonFontColor: map-get($faColors, midBlue),
    activeButtonBackgroundColor: map-get($faColors, faYellow),
    borderColor: map-get($faColors, faLightBlue),
  ),
  smartAccaCompetition: (
    buttonGroupBackgroundColor: map-get($faColors, lightMidBlue),
    tableHeaderBackgroundColor: map-get($faColors, lessDarkGrey),
    tableRowBackgroundColor: $pureWhite,
    tableRowFirstColumnBackgroundColor: map-get($faColors, veryLightGrey),
    tableRowBorderColor: map-get($pures, 200),
    activeButtonBackground: map-get($faColors, faYellow),
    activeButtonFontColor: map-get($faColors, lightMidBlue),
    inactiveButtonBackground: map-get($faColors, faDarkBlue),
    inactiveButtonFontColor: $pureWhite,
    oddsButtonFontColor: $pureBlack,
    oddsButtonBackground: map-get($pures, 100),
    viewMoreButtonFontColor: map-get($pures, 400),
    teamSelectionFontColor: map-get($faColors, paleBlue),
    scrollBarColor: map-get($faColors, veryLightGrey),
    scrollBarLightColor: lighten(map-get($faColors, veryLightGrey), 5%),
    scrollBarBackgroundColor: map-get($faColors, lightMidBlue),
  ),
  select: (
    activeFontColor: map-get($cools, 100),
    backgroundColor: map-get($faColors, lightMidBlue),
    fontColor: map-get($faColors, paleBlue),
    borderBottom: map-get($pureWhiteGradients, 200),
  ),
  stayConnected: (
    fontColor: $pureWhite,
    x: map-get($stayConnectedLinks, x),
    facebook: map-get($stayConnectedLinks, facebook),
    instagram: map-get($stayConnectedLinks, instagram),
    youtube: map-get($stayConnectedLinks, youtube),
  ),
  formIcons: (
    win: map-get($results, winner),
    loss: map-get($results, loser),
    draw: map-get($results, draw),
  ),
  bulletpointOfferCard: (
    buttonBackgroundColor: map-get($faColors, smartAccaGreen),
    buttonFontColor: $pureWhite,
    bulletpointBackgroundColor: map-get($faColors, midBlue),
    contentBackgroundColor: map-get($faColors, faLightModeBackground),
    offerBackgroundColor: $pureWhite,
    tAndCsTextColor: map-get($faColors, midBlue),
  ),
);

$fa-dark: (
  background: map-get($faColors, faDarkBlue),
  contentBackground: map-get($faColors, midBlue),
  subText: map-get($cools, 700),
  contentHeaderFontColor: $pureWhite,
  contentHeaderBackground: map-get($faColors, faLightBlue),
  defaultText: $pureWhite,
  timeToThink: $timeToThink,
  accent: map-get($faColors, faYellow),
  darkerBorder: map-get($faColors, faDarkBlue),
  border: map-get($faColors, faDarkBlue),
  activeElement: map-get($faColors, faYellow),
  inactiveElement: map-get($faColors, faDarkBlue),
  formResultMetaFontColor: map-get($pures, 300),
  cardWrapper: (
    viewAllIconBackgroundColor: map-get($faColors, faLightBlue),
    competitionHeaderBackgroundColor:
      map-get($cardWrapper, darkCompetitionHeaderBackgroundColor),
    highlight: map-get($cardWrapper, highlightColor),
  ),
  betslip: (
    background: map-get($faColors, midBlue),
    defaultText: $pureWhite,
    highlight: $pureWhite,
    secondary: map-get($cools, 800),
    clearButtonFontColor: map-get($faColors, paleBlue),
    borderBottomColor: map-get($faColors, faDarkBlue),
    disabledButtonBackgroundColor: map-get($results, loser),
    enabledButtonBackgroundColor: map-get($results, winner),
    selectionBorderBottomColor: map-get($pureWhiteGradients, 100),
    clearAllButtonBackgroundColor: $pureWhite,
    clearAllButtonFontColor: map-get($faColors, faDarkBlue),
    labelPrimaryColor: map-get($betSlipLabels, defaultPrimary),
    labelBackgroundColor: map-get($betSlipLabels, defaultBackground),
    fastBetLabelPrimaryColor: map-get($betSlipLabels, fastBetPrimary),
    fastBetLabelBackgroundColor: map-get($betSlipLabels, fastBetBackground),
    betStakeBorder: 1px dashed var(--betslip-highlight),
  ),
  buttonPill: (
    activeBackgroundColor: map-get($faColors, faYellow),
    activeFontColor: map-get($cools, 700),
    inactiveBackgroundColor: map-get($faColors, lightMidBlue),
    inactiveFontColor: $pureWhite,
    borderColor: $darkBorderColor,
    toggleFontColor: $pureWhite,
  ),
  card: (
    contentHover: map-get($pureBlackGradients, 200),
  ),
  bookmakerPortal: (
    buttonEmptyBackgroundColor: map-get($faColors, faDarkBlue),
    buttonFillBackgroundColor: $pureWhite,
    subHeaderBorder: map-get($pureWhiteGradients, 100),
    darkBorder: map-get($faColors, lightMidBlue),
    lightBorder: $pureWhite,
    darkText: $pureBlack,
    fadedText: map-get($faColors, paleBlue),
    defaultText: $pureWhite,
    alertBackground: $pureWhite,
  ),
  ctaButton: (
    fontColor: map-get($ctaButton, fontColor),
    backgroundColor: map-get($ctaButton, backgroundColor),
    fontColorSecondary: map-get($ctaButton, fontColorSecondary),
    backgroundColorSecondary: map-get($ctaButton, backgroundColorSecondary),
  ),
  editor: (
    fontColour: $pureWhite,
    sporcle: "invert(1)",
    blockquoteBorder: map-get($faColors, faYellow),
  ),
  footer: (
    fontColor: $pureWhite,
    backgroundColor: map-get($faColors, faDarkBlue),
    navBackgroundColor: map-get($faColors, faDarkBlue),
  ),
  oddsFormatPicker: (
    fontColor: $pureWhite,
    activeFontColor: $pureBlack,
    activeBackgroundColor: $pureWhite,
    border: $pureWhite,
  ),
  leagueTable: (
    winnerBorderColor: map-get($results, winner),
    playoffBorderColor: map-get($results, draw),
    loserBorderColor: map-get($results, loser),
    highlightedRowColor: map-get($faColors, faLightBlue),
    borderColor: map-get($faColors, faLightBlue),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($faColors, paleBlue),
  ),
  header: (
    backgroundColor: map-get($faColors, faYellow),
    secondaryColor: map-get($cools, 900),
    activeSecondaryColor: map-get($faColors, faDarkBlue),
    fontColor: map-get($faColors, faDarkBlue),
    moreMenuBackground: map-get($faColors, faDarkBlue),
    moreMenuBoxShadow: map-get($pureBlackGradients, 400),
    moreMenuIconsBorder: map-get($pureBlackGradients, 200),
    moreMenuIconsBackground: map-get($faColors, faDarkBlue),
    moreMenuFontColor: $pureWhite,
  ),
  mobileNav: (
    backgroundColor: map-get($cools, 900),
    menuHeadingColor: map-get($faColors, midBlue),
    fontColor: $pureWhite,
    menuBorderColor: map-get($cools, 800),
    menuIconFill: map-get($faColors, faDarkBlue),
    menuIconsBorder: map-get($pureBlackGradients, 200),
    navButtonBorderRadius: 0,
    navButtonBackgroundColor: "transparent",
  ),
  secondaryNav: (
    background: map-get($faColors, midBlue),
    desktopLinkBackground: map-get($faColors, faLightBlue),
    linkBackgroundColor: map-get($faColors, faYellow),
    linkHoverText: $pureBlack,
    activeLinkColor: map-get($faColors, faDarkBlue),
  ),
  notifications: (
    backgroundColor: map-get($notifications, backgroundColor),
    fontColor: map-get($notifications, fontColor),
    sideMenuBackgroundColor: map-get($notifications, backgroundColor),
    sideMenuFontColor: $pureBlack,
  ),
  dataTable: (
    tableHeaderColor: map-get($faColors, midBlue),
    secondaryFontColor: $pureWhite,
    evenRowsColor: map-get($faColors, midBlue),
  ),
  pagination: (
    activeBackgroundColor: map-get($faColors, lightMidBlue),
    activeFontColor: $pureWhite,
  ),
  tipsterBetBuilder: (
    backgroundColor: map-get($faColors, midBlue),
    borderColor: map-get($faColors, lightMidBlue),
    secondaryBorderColor: $pureWhite,
    activeButtonColor: $pureWhite,
    activeButtonFontColor: map-get($faColors, midBlue),
    buttonFontColor: $pureWhite,
  ),
  betType: (
    defaultText: $pureWhite,
    backgroundColor: map-get($faColors, midBlue),
  ),
  tab: (
    defaultText: $pureWhite,
    activeText: $pureBlack,
    underlineColor: $pureWhite,
    inactiveTabColor: map-get($faColors, faLightBlue),
    activeTabColor: map-get($faColors, faYellow),
    activeTabUnderlineColor: map-get($faColors, faYellow),
    tabContainerBackgroundColor: $pureWhite,
  ),
  matchOverview: (
    overlay: map-get($pureBlackGradients, 800),
    scoreBackground: $pureWhite,
    scoreText: $pureBlack,
    competitionFontColor: map-get($faColors, paleBlue),
    winnerSolidColor: map-get($results, winner),
    winnerFadedColor: map-get($results, winnerFaded),
    drawSolidColor: map-get($results, draw),
    drawFadedColor: map-get($results, drawFaded),
    loserSolidColor: map-get($results, loser),
    loserFadedColor: map-get($results, loserFaded),
  ),
  matchOverviewInsights: (
    backgroundColor: map-get($faColors, faDarkTipBlue),
    borderColor: map-get($faColors, faDarkTipBorder),
    separator: map-get($faColors, faLightBlue),
    buttonSeparator: $pureBlack,
  ),
  modal: (
    overlayBackgroundColor: map-get($pureBlackGradients, 600),
    allOffersButtonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
    headerBackgroundColor: map-get($faColors, lightMidBlue),
    containerBackgroundColor: map-get($faColors, midBlue),
    primaryTextColor: $pureWhite,
    allOffersButtonBackgroundColor:
      map-get($modal, allOffersButtonBackgroundColor),
  ),
  interstitial: (
    containerBackgroundColor: map-get($faColors, faDarkBlue),
    featuredContainerBackgroundColor: map-get($faColors, faYellow),
    featuredContainerFontColor: $pureBlack,
    contentFontColor: $pureWhite,
    buttonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
  ),
  statsTipster: (
    borderColor: map-get($faColors, lightMidBlue),
    secondaryBorderColor: map-get($faColors, faDarkBlue),
    dashedBorderColor: map-get($faColors, lightendedPaleBlue),
    subText: map-get($faColors, paleBlue),
    statsButtonBackground: map-get($faColors, midBlue),
    statsButtonColor: $pureWhite,
    statsIconColor: map-get($faColors, paleBlue),
    activeStatsButtonBackground: map-get($faColors, faLightBlue),
    activeStatsButtonColor: $pureWhite,
    activeStatsBorderColor: map-get($faColors, faLightBlue),
    activeStatsIconColor: map-get($faColors, faYellow),
    reasonBorderColor: map-get($faColors, lightendedPaleBlue),
    reasonBackgroundColor: map-get($faColors, midBlue),
    reasonMarketColor: map-get($faColors, faYellow),
    teamSelector: map-get($faColors, faYellow),
    tableFontColor: map-get($faColors, paleBlue),
    inactiveTabFontColor: map-get($faColors, paleBlue),
  ),
  banner: (
    backgroundColor: $pureBlack,
  ),
  otherTips: (
    borderColor: map-get($otherTips, borderColor),
  ),
  smartAccaMatch: (
    primaryBackgroundColor: map-get($faColors, midBlue),
    secondaryBackgroundColor: map-get($faColors, faDarkBlue),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($faColors, paleBlue),
    highProbabilityFontColor: map-get($faColors, smartAccaGreen),
    buttonFontColor: $pureBlack,
    buttonBackgroundColor: $pureWhite,
    activeButtonBackgroundColor: map-get($faColors, faYellow),
    scrollBarColor: map-get($faColors, midBlue),
    scrollBarBackgroundColor: map-get($faColors, faDarkBlue),
    borderColor: map-get($faColors, faLightBlue),
  ),
  suggestedSmartAcca: (
    primaryBackgroundColor: map-get($faColors, midBlue),
    secondaryBackgroundColor: map-get($faColors, faDarkBlue),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($faColors, paleBlue),
    buttonBackgroundColor: $pureWhite,
    buttonFontColor: map-get($faColors, midBlue),
    activeButtonBackgroundColor: map-get($faColors, faYellow),
    borderColor: map-get($faColors, faLightBlue),
  ),
  smartAccaCompetition: (
    buttonGroupBackgroundColor: map-get($faColors, midBlue),
    tableHeaderBackgroundColor: map-get($faColors, lessDarkBlue),
    tableRowBackgroundColor: map-get($faColors, lessDarkBlue),
    tableRowFirstColumnBackgroundColor: map-get($faColors, midBlue),
    tableRowBorderColor: map-get($faColors, lightendedPaleBlue),
    activeButtonBackground: map-get($faColors, faYellow),
    activeButtonFontColor: map-get($faColors, lightMidBlue),
    inactiveButtonBackground: map-get($faColors, faDarkBlue),
    inactiveButtonFontColor: $pureWhite,
    oddsButtonFontColor: $pureBlack,
    oddsButtonBackground: map-get($pures, 100),
    viewMoreButtonFontColor: map-get($faColors, paleBlue),
    teamSelectionFontColor: map-get($faColors, paleBlue),
    scrollBarColor: map-get($faColors, midBlue),
    scrollBarLightColor: lighten(map-get($faColors, midBlue), 5%),
    scrollBarBackgroundColor: map-get($faColors, lessDarkBlue),
  ),
  select: (
    activeFontColor: map-get($cools, 100),
    backgroundColor: map-get($faColors, lightMidBlue),
    fontColor: map-get($faColors, paleBlue),
    borderBottom: map-get($pureWhiteGradients, 200),
  ),
  stayConnected: (
    fontColor: $pureWhite,
    x: map-get($stayConnectedLinks, x),
    facebook: map-get($stayConnectedLinks, facebook),
    instagram: map-get($stayConnectedLinks, instagram),
    youtube: map-get($stayConnectedLinks, youtube),
  ),
  formIcons: (
    win: map-get($results, winner),
    loss: map-get($results, loser),
    draw: map-get($results, draw),
  ),
  bulletpointOfferCard: (
    buttonBackgroundColor: map-get($faColors, smartAccaGreen),
    buttonFontColor: $pureWhite,
    bulletpointBackgroundColor: map-get($faColors, faYellow),
    contentBackgroundColor: map-get($faColors, faDarkBlue),
    offerBackgroundColor: map-get($faColors, lightMidBlue),
    tAndCsTextColor: map-get($faColors, pastelBlue),
  ),
);

$fp: (
  background: map-get($pures, 100),
  contentBackground: $pureWhite,
  contentHeaderFontColor: $pureWhite,
  contentHeaderBackground: map-get($flashPicksBlue, 300),
  defaultText: $pureBlack,
  accent: map-get($flashPicksBlue, 200),
  activeElement: map-get($flashPicksBlue, 100),
  inactiveElement: map-get($cools, 200),
  formResultMetaFontColor: map-get($cools, 800),
  darkerBorder: map-get($pures, 200),
  border: map-get($cools, 200),
  cardWrapper: (
    viewAllIconBackgroundColor: map-get($flashPicksBlue, 200),
    competitionHeaderBackgroundColor: map-get($flashPicksBlue, 300),
    highlight: map-get($cardWrapper, highlightColor),
  ),
  card: (
    contentHover: map-get($pureBlackGradients, 200),
  ),
  ctaButton: (
    fontColor: map-get($ctaButton, fontColor),
    backgroundColor: map-get($ctaButton, backgroundColor),
    fontColorSecondary: map-get($ctaButton, fontColorSecondary),
    backgroundColorSecondary: $flashPicksPaleBlue,
  ),
  betslip: (
    background: map-get($flashPicksBlue, 300),
    defaultText: $pureWhite,
    highlight: $pureWhite,
    secondary: map-get($pureWhiteGradients, 200),
    clearButtonFontColor: $pureWhite,
    borderBottomColor: map-get($pureWhiteGradients, 200),
    disabledButtonBackgroundColor: map-get($results, loser),
    enabledButtonBackgroundColor: map-get($results, winner),
    selectionBorderBottomColor: map-get($pureWhiteGradients, 100),
    clearAllButtonBackgroundColor: $pureWhite,
    clearAllButtonFontColor: $pureBlack,
    labelPrimaryColor: map-get($betSlipLabels, defaultPrimary),
    labelBackgroundColor: map-get($betSlipLabels, defaultBackground),
    fastBetLabelPrimaryColor: map-get($betSlipLabels, fastBetPrimary),
    fastBetLabelBackgroundColor: map-get($betSlipLabels, fastBetBackground),
    betStakeBorder: 1px solid var(--betslip-borderBottomColor),
  ),
  editor: (
    fontColour: $pureBlack,
    sporcle: "none",
    blockquoteBorder: map-get($flashPicksBlue, 200),
  ),
  footer: (
    fontColor: $pureWhite,
    backgroundColor: map-get($flashPicksBlue, 300),
    navBackgroundColor: map-get($pureBlackGradients, 100),
  ),
  oddsFormatPicker: (
    fontColor: $pureWhite,
    activeFontColor: $pureBlack,
    activeBackgroundColor: $pureWhite,
    border: $pureWhite,
  ),
  header: (
    backgroundColor: map-get($flashPicksBlue, 100),
    secondaryColor: map-get($cools, 300),
    activeSecondaryColor: $flashPicksYellow,
    fontColor: $pureWhite,
    moreMenuBackground: map-get($flashPicksBlue, 100),
    moreMenuBoxShadow: map-get($pureBlackGradients, 400),
    moreMenuIconsBorder: map-get($pureWhiteGradients, 300),
    moreMenuIconsBackground: map-get($flashPicksBlue, 200),
    moreMenuFontColor: $pureWhite,
  ),
  mobileNav: (
    backgroundColor: map-get($flashPicksBlue, 100),
    menuHeadingColor: map-get($flashPicksBlue, 200),
    fontColor: $pureWhite,
    menuBorderColor: map-get($pureWhiteGradients, 200),
    menuIconFill: map-get($cools, 300),
    menuIconsBorder: map-get($pureWhiteGradients, 200),
    navButtonBorderRadius: 50%,
    navButtonBackgroundColor: map-get($flashPicksBlue, 200),
  ),
  secondaryNav: (
    background: map-get($flashPicksBlue, 200),
    desktopLinkBackground: map-get($flashPicksBlue, 100),
    linkBackgroundColor: $flashPicksYellow,
    linkHoverText: $pureBlack,
    activeLinkColor: map-get($flashPicksBlue, 900),
  ),
  notifications: (
    backgroundColor: map-get($notifications, backgroundColor),
    fontColor: map-get($notifications, fontColor),
    sideMenuBackgroundColor: map-get($notifications, backgroundColor),
    sideMenuFontColor: $pureWhite,
  ),
  dataTable: (
    tableHeaderColor: map-get($flashPicksBlue, 300),
    secondaryFontColor: $pureWhite,
    evenRowsColor: map-get($flashPicksBlue, 300),
  ),
  pagination: (
    activeBackgroundColor: map-get($flashPicksBlue, 100),
    activeFontColor: $pureWhite,
  ),
  betType: (
    defaultText: $pureWhite,
    backgroundColor: map-get($flashPicksBlue, 300),
  ),
  tab: (
    defaultText: $pureBlack,
    activeText: $pureWhite,
    underlineColor: $pureWhite,
    inactiveTabColor: map-get($cools, 100),
    activeTabColor: $flashPicksYellow,
    activeTabUnderlineColor: $flashPicksYellow,
    tabContainerBackgroundColor: $pureWhite,
  ),
  tipsterBetBuilder: (
    backgroundColor: map-get($tipster, backgroundColor),
    borderColor: map-get($tipster, borderColor),
    secondaryBorderColor: map-get($tipster, secondaryColor),
    activeButtonColor: map-get($tipster, activeButtonColor),
    activeButtonFontColor: $pureWhite,
    buttonFontColor: map-get($tipster, secondaryColor),
  ),
  matchOverview: (
    overlay: map-get($pureBlackGradients, 800),
    scoreBackground: $pureWhite,
    scoreText: $pureBlack,
    competitionFontColor: $pureBlack,
    winnerSolidColor: map-get($results, winner),
    winnerFadedColor: map-get($results, winnerFaded),
    drawSolidColor: map-get($results, draw),
    drawFadedColor: map-get($results, drawFaded),
    loserSolidColor: map-get($results, loser),
    loserFadedColor: map-get($results, loserFaded),
  ),
  modal: (
    overlayBackgroundColor: map-get($pureBlackGradients, 600),
    allOffersButtonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
    headerBackgroundColor: map-get($flashPicksBlue, 100),
    containerBackgroundColor: $pureWhite,
    primaryTextColor: $pureWhite,
    allOffersButtonBackgroundColor:
      map-get($modal, allOffersButtonBackgroundColor),
  ),
  interstitial: (
    containerBackgroundColor: map-get($pures, 200),
    featuredContainerBackgroundColor: map-get($flashPicksBlue, 300),
    featuredContainerFontColor: $pureWhite,
    contentFontColor: $pureBlack,
    buttonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
  ),
  statsTipster: (
    borderColor: map-get($tipster, borderColor),
    secondaryBorderColor: map-get($tipster, borderColor),
    dashedBorderColor: map-get($tipster, borderColor),
    subText: map-get($pures, 500),
    statsButtonBackground: $flashPicksPaleBlue,
    statsButtonColor: $pureBlack,
    statsIconColor: $pureBlack,
    activeStatsButtonBackground: map-get($flashPicksBlue, 100),
    activeStatsButtonColor: $pureWhite,
    activeStatsBorderColor: map-get($tipster, reasonBorderColor),
    activeStatsIconColor: $pureWhite,
    reasonBorderColor: map-get($tipster, reasonBorderColor),
    reasonBackgroundColor: $flashPicksPaleBlue,
    reasonMarketColor: $pureBlack,
    teamSelector: $flashPicksRed,
    tableFontColor: map-get($faColors, lightMidBlue),
    inactiveTabFontColor: map-get($fpGreys, 200),
  ),
  banner: (
    backgroundColor: $pureWhite,
  ),
  smartAccaMatch: (
    primaryBackgroundColor: map-get($flashPicksBlue, 300),
    secondaryBackgroundColor: map-get($flashPicksBlue, 100),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($cools, 200),
    highProbabilityFontColor: $flashPicksGreen,
    buttonFontColor: $pureBlack,
    buttonBackgroundColor: $pureWhite,
    activeButtonBackgroundColor: $flashPicksYellow,
    scrollBarColor: map-get($flashPicksBlue, 300),
    scrollBarBackgroundColor: map-get($flashPicksBlue, 100),
    borderColor: map-get($flashPicksBlue, 200),
  ),
  suggestedSmartAcca: (
    primaryBackgroundColor: map-get($flashPicksBlue, 300),
    secondaryBackgroundColor: map-get($flashPicksBlue, 100),
    primaryFontColor: $pureWhite,
    secondaryFontColor: map-get($cools, 200),
    buttonBackgroundColor: $pureWhite,
    buttonFontColor: map-get($flashPicksBlue, 300),
    activeButtonBackgroundColor: $flashPicksYellow,
    borderColor: map-get($flashPicksBlue, 200),
  ),
  smartAccaCompetition: (
    buttonGroupBackgroundColor: map-get($flashPicksBlue, 200),
    tableHeaderBackgroundColor: map-get($fpGreys, 200),
    tableRowBackgroundColor: $pureWhite,
    tableRowFirstColumnBackgroundColor: map-get($fpGreys, 100),
    tableRowBorderColor: map-get($pures, 200),
    activeButtonBackground: $flashPicksYellow,
    activeButtonFontColor: $pureBlack,
    inactiveButtonBackground: map-get($flashPicksBlue, 100),
    inactiveButtonFontColor: $pureWhite,
    oddsButtonFontColor: $pureBlack,
    oddsButtonBackground: map-get($pures, 100),
    viewMoreButtonFontColor: map-get($pures, 400),
    teamSelectionFontColor: map-get($fpGreys, 300),
    scrollBarColor: map-get($fpGreys, 100),
    scrollBarLightColor: lighten(map-get($fpGreys, 100), 5%),
    scrollBarBackgroundColor: map-get($flashPicksBlue, 200),
  ),
  select: (
    activeFontColor: map-get($cools, 100),
    backgroundColor: map-get($flashPicksBlue, 100),
    fontColor: $pureWhite,
    borderBottom: map-get($pureWhiteGradients, 200),
  ),
  stayConnected: (
    fontColor: $pureWhite,
    x: map-get($stayConnectedLinks, x),
    facebook: map-get($stayConnectedLinks, facebook),
    instagram: map-get($stayConnectedLinks, instagram),
    youtube: map-get($stayConnectedLinks, youtube),
  ),
  matchCenter: (
    primaryBackgroundColor: $pureWhite,
    secondaryBackgroundColor: map-get($flashPicksBlue, 300),
    matchInfoFontColor: map-get($flashPicksBlue, 100),
    borderColor: map-get($pures, 200),
    activeSelectionBackgroundColor: $flashPicksYellow,
    oddsContainerBackgroundColor:
      map-get($matchCenter, oddsContainerBackgroundColor),
  ),
  leagueTable: (
    winnerBorderColor: map-get($results, winner),
    playoffBorderColor: map-get($results, draw),
    loserBorderColor: map-get($results, loser),
    highlightedRowColor: map-get($pures, 100),
    borderColor: map-get($pures, 100),
    primaryFontColor: $pureBlack,
    secondaryFontColor: map-get($pures, 600),
  ),
  stateSelector: (
    primaryFontColor: $pureWhite,
    secondaryFontColor: $pureBlack,
    headerBorderBottomColor: $flashPicksPaleBlue,
    dropdownHeaderColor: map-get($flashPicksBlue, 100),
    dropdownShadowColor: map-get($pureBlackGradients, 500),
    scrollBarColor: $flashPicksPaleBlue,
    scrollBarBorderColor: map-get($flashPicksBlue, 100),
  ),
  formIcons: (
    win: map-get($results, winner),
    loss: map-get($results, loser),
    draw: map-get($results, draw),
  ),
  bulletpointOfferCard: (
    buttonBackgroundColor: $flashPicksGreen,
    buttonFontColor: $pureWhite,
    bulletpointBackgroundColor: map-get($flashPicksBlue, 300),
    contentBackgroundColor: map-get($pures, 100),
    offerBackgroundColor: $pureWhite,
    tAndCsTextColor: $pureBlack,
  ),
);

$twe: (
  background: map-get($pures, 100),
  contentBackground: $pureWhite,
  contentHeaderFontColor: $pureWhite,
  contentHeaderBackground: map-get($tweGreen, 400),
  defaultText: map-get($cools, 700),
  timeToThink: $timeToThink,
  accent: map-get($tweGreen, 300),
  activeElement: map-get($tweGreen, 400),
  inactiveElement: map-get($pures, 200),
  darkerBorder: map-get($cools, 300),
  border: map-get($pures, 200),
  card: (
    contentHover: map-get($pureBlackGradients, 200),
  ),
  cardWrapper: (
    viewAllIconBackgroundColor: map-get($tweGreen, 500),
    highlight: map-get($cardWrapper, highlightColor),
    competitionHeaderBackgroundColor:
      map-get($cardWrapper, lightCompetitionHeaderBackgroundColor),
  ),
  ctaButton: (
    fontColor: map-get($ctaButton, fontColor),
    backgroundColor: map-get($ctaButton, backgroundColor),
    fontColorSecondary: map-get($ctaButton, fontColorSecondary),
    backgroundColorSecondary: map-get($ctaButton, backgroundColorSecondary),
  ),
  editor: (
    fontColour: map-get($cools, 700),
    sporcle: "none",
    blockquoteBorder: map-get($tweGreen, 300),
  ),
  footer: (
    fontColor: $pureWhite,
    backgroundColor: map-get($tweGreen, 400),
    navBackgroundColor: map-get($pureBlackGradients, 100),
  ),
  oddsFormatPicker: (
    fontColor: $pureWhite,
    activeFontColor: map-get($tweGreen, 400),
    activeBackgroundColor: $pureWhite,
    border: $pureWhite,
  ),
  header: (
    backgroundColor: map-get($tweGreen, 300),
    secondaryColor: $pureWhite,
    activeSecondaryColor: $tweGold,
    fontColor: $pureWhite,
    moreMenuBackground: map-get($tweGreen, 100),
    moreMenuBoxShadow: "transparent",
    moreMenuIconsBorder: map-get($pureWhiteGradients, 200),
    moreMenuIconsBackground: map-get($tweGreen, 200),
    moreMenuFontColor: $pureWhite,
  ),
  mobileNav: (
    backgroundColor: map-get($tweGreen, 300),
    menuHeadingColor: map-get($tweGreen, 200),
    fontColor: $pureWhite,
    menuBorderColor: map-get($tweGreen, 500),
    menuIconFill: $pureWhite,
    menuIconsBorder: map-get($tweGreen, 500),
    navButtonBorderRadius: 50%,
    navButtonBackgroundColor: map-get($tweGreen, 100),
  ),
  secondaryNav: (
    background: map-get($tweGreen, 200),
    desktopLinkBackground: map-get($tweGreen, 100),
    linkBackgroundColor: $tweGold,
    linkHoverText: $pureBlack,
    activeLinkColor: map-get($tweBlue, 900),
  ),
  notifications: (
    backgroundColor: $tweGold,
    fontColor: map-get($tweGreen, 200),
    sideMenuBackgroundColor: $tweLightGreen,
    sideMenuFontColor: $tweGrey,
  ),
  pagination: (
    activeBackgroundColor: map-get($pures, 200),
    activeFontColor: map-get($cools, 700),
  ),
  modal: (
    overlayBackgroundColor: map-get($pureBlackGradients, 600),
    boxShadowColor: map-get($pureBlackGradients, 300),
    allOffersButtonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
    headerBackgroundColor: map-get($tweGreen, 400),
    containerBackgroundColor: $pureWhite,
    primaryTextColor: $pureWhite,
    secondaryTextColor: $pureBlack,
    allOffersButtonBackgroundColor:
      map-get($modal, allOffersButtonBackgroundColor),
    headerBorderColor: map-get($tweLightGrey, 200),
  ),
  interstitial: (
    containerBackgroundColor: map-get($pures, 200),
    featuredContainerBackgroundColor: map-get($tweGreen, 200),
    featuredContainerFontColor: $pureWhite,
    contentFontColor: $pureBlack,
    buttonOnHoverColor: map-get($modal, allOffersButtonOnHoverColor),
  ),
  banner: (
    backgroundColor: $pureWhite,
  ),
  otherTips: (
    borderColor: map-get($otherTips, borderColor),
  ),
  stayConnected: (
    fontColor: $pureWhite,
    x: map-get($stayConnectedLinks, x),
    facebook: map-get($stayConnectedLinks, facebook),
    instagram: map-get($stayConnectedLinks, instagram),
    youtube: map-get($stayConnectedLinks, youtube),
  ),
  raceSelector: (
    defaultText: $pureBlack,
    secondaryText: $tweGrey,
    background: $pureWhite,
    meta: map-get($tweGreen, 500),
    footerBorderTop: map-get($tweLightGrey, 300),
  ),
  formDropdown: (
    backgroundColor: map-get($tweLightGrey, 100),
    color: $pureBlack,
    icon: $tweLightGreen,
    tableHeaderBackground: map-get($tweLightGrey, 200),
  ),
  bulletpointOfferCard: (
    buttonBackgroundColor: map-get($ctaButton, backgroundColor),
    buttonFontColor: $pureWhite,
    bulletpointBackgroundColor: map-get($ctaButton, backgroundColor),
    contentBackgroundColor: map-get($pures, 100),
    offerBackgroundColor: $pureWhite,
    tAndCsTextColor: map-get($cardWrapper, darkCompetitionHeaderBackgroundColor),
  ),
);
