@use "@/styles/constants/devices.scss" as *;

.menuUl {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuLi {
  text-align: center;
  font-size: 0.825rem;
  border-bottom: 1px solid var(--mobileNav-menuBorderColor);

  &:last-child {
    border-bottom: none;
  }

  @media only screen and (min-width: $tablet) {
    text-align: left;
  }
}

.menuLink {
  text-decoration: none;
  display: block;
  padding: 1rem;
  color: var(--header-moreMenuFontColor);

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.offerCount {
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  background-color: var(--notifications-sideMenuBackgroundColor);
  font-weight: 700;
  width: 23px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 0.5rem;
  color: var(--notifications-sideMenuFontColor);

  @media only screen and (min-width: $tablet) {
    display: none;
  }
}

.gambleAware {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  opacity: 0.75;

  @media only screen and (min-width: $tablet) {
    display: none;
  }
}

.gamblingAge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 2px solid var(--mobileNav-fontColor);
  border-radius: 50%;
  color: var(--mobileNav-fontColor);
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;

  & > span {
    margin-bottom: auto;
  }
}

.imageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gambleAwareImage {
  max-width: 200px;
  object-fit: contain;
  height: fit-content;

  &--fp {
    margin-left: 25px;
  }
}
