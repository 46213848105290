@import "@/styles/brands/colors/crossBrand.scss";
@import "@/styles/brands/colors/faColors.scss";
@import "@/styles/brands/colors/fpColors.scss";
@import "@/styles/brands/colors/tweColors.scss";
@import "@/styles/brands/theme.scss";
@import "@/styles/brands/globals.scss";

$color-prefix: --;

[data-theme="fa"] {
  @each $name, $color in $fa {
    @if type-of($color) == "map" {
      @each $subname, $subcolor in $color {
        #{$color-prefix}#{$name}-#{$subname}: $subcolor;
      }
    } @else if type-of($color) == "color" {
      #{$color-prefix}#{$name}: $color;
    }
  }

  background: var(--background);
  color: var(--defaultText);
}

[data-theme="fa-dark"] {
  @each $name, $color in $fa-dark {
    @if type-of($color) == "map" {
      @each $subname, $subcolor in $color {
        #{$color-prefix}#{$name}-#{$subname}: $subcolor;
      }
    } @else if type-of($color) == "color" {
      #{$color-prefix}#{$name}: $color;
    }
  }

  background: var(--background);
  color: var(--defaultText);
}

[data-theme="fp"] {
  @each $name, $color in $fp {
    @if type-of($color) == "map" {
      @each $subname, $subcolor in $color {
        #{$color-prefix}#{$name}-#{$subname}: $subcolor;
      }
    } @else if type-of($color) == "color" {
      #{$color-prefix}#{$name}: $color;
    }
  }

  background: var(--background);
  color: var(--defaultText);
}

[data-theme="twe"] {
  @each $name, $color in $twe {
    @if type-of($color) == "map" {
      @each $subname, $subcolor in $color {
        #{$color-prefix}#{$name}-#{$subname}: $subcolor;
      }
    } @else if type-of($color) == "color" {
      #{$color-prefix}#{$name}: $color;
    }
  }

  background: var(--background);
  color: var(--defaultText);
}
