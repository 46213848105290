@use '../../../styles/devices.scss' as *;

.tipContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.tip {
  margin: 1rem;
  text-transform: uppercase;
  font-size: 13px;
  word-spacing: 0.03rem;
}

.button {
  border: none;
  background-color: transparent;
  margin: 1rem;
}

.icon {
  color: var(--defaultText);
}

.link {
  margin: 0px 16px 12px 16px;
  text-decoration: none;
  color: var(--ctaButton-fontColor);
  background-color: var(--ctaButton-backgroundColor);
  font-size: 13px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 4px;

  @media screen and (min-width: $tablet) {
    margin: 12px 16px 12px 0px;
    min-width: 25%;
  }
}

.reasonContainer {
  border-left: 4px solid var(--cardWrapper-highlight);
  padding: 0.5rem;
}

.reason {
  margin: 0;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}
