.ppcTable {
  > div {
    background-color: transparent;
    > div {
      &:not(:first-child) {
        background-color: var(--bulletpointOfferCard-contentBackgroundColor);
        padding: 16px 0 0;
      }
    }
  }
}
