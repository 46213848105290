@use "@/styles/constants/devices.scss" as *;

.footerLegislationContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;

  @media only screen and (min-width: $tablet) {
    margin-bottom: 16px;
  }
}

.footerLegalGamblingAge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 2px solid var(--footer-fontColor);
  border-radius: 50%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.5rem;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $tablet) {
    flex-direction: row;
    align-items: center;
  }

  a {
    &:nth-child(2) {
      padding-left: 40px;
      @media only screen and (min-width: $tablet) {
        padding-left: 0;
      }
    }
  }
}

.problemGamblingLink {
  margin-left: 25px;
}

.gambleAwareImage {
  max-width: 100%;
  height: auto;
}
