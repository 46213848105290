@use "../../styles/devices.scss" as *;

.icon {
  display: initial;
  box-sizing: border-box;
  border-radius: 50%;
  list-style: none;

  &--W {
    background-color: var(--formIcons-win);
  }
  &--D {
    background-color: var(--formIcons-draw);
  }
  &--L {
    background-color: var(--formIcons-loss);
  }
  &--LW {
    border: 1px solid var(--formIcons-win);
  }
  &--LD {
    border: 1px solid var(--formIcons-draw);
  }
  &--LL {
    border: 1px solid var(--formIcons-loss);
  }

  @media screen and (min-width: $laptop) {
    margin: 0 5px !important;

    &--lastLaptopIcon {
      height: 16px !important;
      width: 16px !important;
    }

    &--laptopIcon {
      height: 12px !important;
      width: 12px !important;
    }
  }
}
