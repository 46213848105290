.tableContainer {
  margin: 1rem 0;
  overflow-x: auto;
  max-width: 100vw;
}

.table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
  font-size: 13px;
  border-radius: 3px;
}

.tableHeader {
  background-color: var(--dataTable-tableHeaderColor);
  color: var(--dataTable-secondaryFontColor);
  padding: 1rem;

  &:first-child {
    border-radius: 8px 0 0;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }
}

.tableCell {
  padding: 1rem;
}

.tableRow {
  font-weight: 500;

  &:nth-child(even) {
    background-color: var(--dataTable-evenRowsColor);
    color: var(--dataTable-secondaryFontColor);
  }
}
