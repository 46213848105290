@use "../../../styles/devices.scss" as *;

.bookmakers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px 16px;

  & > div {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2.5fr;
    align-items: center;
    column-gap: 11px;

    &:nth-child(2n + 1) {
      border-bottom: 1px dashed var(--statsTipster-dashedBorderColor);
      border-right: 1px dashed var(--statsTipster-dashedBorderColor);
      padding: 16px 16px 16px 0;
    }
    &:nth-last-child(2) {
      border-bottom: none;
    }

    &:nth-child(2n) {
      border-bottom: 1px dashed var(--statsTipster-dashedBorderColor);
      padding: 16px 0 16px 16px;
    }
    &:last-child {
      border-bottom: none;
    }

    @media screen and (max-width: $laptop) {
      border-right: none !important;

      &:nth-child(2n + 1) {
        border-bottom: 1px dashed var(--statsTipster-dashedBorderColor);
        padding: 16px 0;
      }

      &:nth-child(2n) {
        border-bottom: 1px dashed var(--statsTipster-dashedBorderColor);
        padding: 16px 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }

    & > div {
      height: 36px;

      &:first-child {
        width: 36px;
      }

      &:last-child {
        grid-column: auto;
      }
    }
  }

  @media screen and (max-width: $laptop) {
    display: block;
  }
}

.viewMoreButtonContainer {
  text-align: center;
  border-top: 1px solid var(--statsTipster-secondaryBorderColor);
  margin: 5px;
  padding: 8px 16px;
}

.viewMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--statsTipster-subText);
  background: none;
  border: none;
  width: 100%;
  margin: 13px 0;

  & > p {
    margin: 0 6px 0 0;
  }
}

.bookmakerReason {
  padding: 8px 16px;

  p {
    margin: 0;
  }
}
