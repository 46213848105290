.offerFormatPickerContainer {
  display: flex;
  height: 40px;
  margin: 16px 0;
}

.offerFormatPicker {
  background-color: transparent;
  border: 1px solid var(--oddsFormatPicker-border);
  color: var(--oddsFormatPicker-fontColor);
  padding: 0 1rem;
  font-size: 0.66rem;

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:nth-child(even) {
    border-left: none;
    border-right: none;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  &[aria-pressed="true"] {
    background-color: var(--oddsFormatPicker-activeBackgroundColor);
    color: var(--oddsFormatPicker-activeFontColor);
    font-weight: 700;
  }
}
