@use "./devices.scss" as *;

.cardListHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--contentHeaderBackground);
  box-sizing: border-box;
  padding: 18px 16px;
  color: var(--contentHeaderFontColor);
  vertical-align: middle;
  margin: 0;
  border-bottom: 1px solid var(--cardWrapper-borderColor);

  &--competitionHeader {
    background-color: var(--competitionHeaderBackgroundColor);
  }

  @media only screen and (min-width: $tablet) {
    border-radius: 8px 8px 0 0;
  }

  @media only screen and (min-width: $laptop) {
    font-size: 1rem;
    padding: 20px 16px;
  }
}

.cardListHeader {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 0.95rem;

  @media only screen and (min-width: $laptop) {
    font-size: 1rem;
  }

  &--imageMarginRight > img {
    margin-right: 8px !important;
  }

  &--competitionHeader {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }
}
