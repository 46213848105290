.mobileNavContainer {
  display: flex;
  align-items: flex-end;
}

.mobileMenuContainer {
  width: 100%;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 128px;
  left: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.4s ease-in-out 0s;
  display: flex;
  flex-direction: column;
  background-color: var(--mobileNav-backgroundColor);
  color: var(--mobileNav-fontColor);
  overflow: auto;
  transform: translateX(-100%);
}

.mobileMenuContainerOpen {
  transform: translateX(0px);
}

.menuHeading {
  font-size: 0.875rem;
  font-weight: 700;
  font-family: var(--font-inter);
  text-transform: none;
  text-align: center;
  max-height: 40px;
  padding: calc(1rem - 1px);
  margin: 0;
  background-color: var(--mobileNav-menuHeadingColor);
  border-bottom: 1px solid var(--mobileNav-menuBorderColor);
}

.mobileNavBtn {
  height: 32px;
  width: 32px;
  border-radius: var(--mobileNav-navButtonBorderRadius);
  background-color: var(--mobileNav-navButtonBackgroundColor);
  border: none;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.mobileNavBtnOpen {
  padding: 0 6px 0 8px;
}
