@use '../../../styles/devices.scss' as *;

.offerCardContainer {
  background-color: var(--contentBackground);
  border: 1px solid var(--darkerBorder);
  border-radius: 8px;
  margin-bottom: 16px;
}

.offerHeader {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid var(--darkerBorder);
  align-items: center;

  @extend .offerHeader;
  @media screen and (min-width: $tablet) {
    display: grid;
    grid-template-columns: 48px 1.2fr 0.8fr;

    &--sidebar {
      display: flex;
    }
  }
}

.offerHeading {
  display: inline;
  align-self: center;
  font-size: 0.8125rem;
  line-height: 1.2em;
  font-weight: 700;
  padding-left: 16px;
  max-width: calc(100% - 16px - 48px);
  text-transform: unset;
}

.offerBody {
  padding: 16px 16px 0;

  @extend .offerBody;
  @media screen and (min-width: $tablet) {
    padding-bottom: 16px;

    &--sidebar {
      padding-bottom: 0;
    }
  }
}

.offerTerms {
  font-size: 0.625rem;
  line-height: 1.4em;
  letter-spacing: 0.05em;
  margin: 0 0 16px;

  @extend .offerTerms;
  @media screen and (min-width: $tablet) {
    margin: 0;

    &--sidebar {
      margin: 0 0 16px;
    }
  }
}

.offerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 0.8125rem;
  font-weight: 700;
  border-radius: 4px;
  margin: 10px 0 16px;
  max-height: 35px;
}
