.link {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  padding: 8px 0;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 0.8rem;
  text-decoration: none;
  background: var(--ctaButton-backgroundColor);
  color: var(--ctaButton-fontColor);
  border-radius: 4px;

  &--secondary {
    background: var(--ctaButton-backgroundColorSecondary);
    color: var(--ctaButton-fontColorSecondary);
  }
}
