.cta-button {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  height: 36px;
  font-weight: 500;
  font-size: 0.8rem;
  text-decoration: none;
  border: none;
  background: var(--ctaButton-backgroundColor);
  color: var(--ctaButton-fontColor);
  border-radius: 4px;

  &--secondary {
    @extend .cta-button;
    background: var(--ctaButton-backgroundColorSecondary);
    color: var(--ctaButton-fontColorSecondary);
  }
}
