body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: calc(13px + 0.2vw);
  font-family: var(--font-inter);
  // added the following line to fix the font size on older iOS devices automatically scaling text
  // this css property will allow users to still zoom in and out on the page
  // https://stackoverflow.com/questions/3226001/some-font-sizes-rendered-larger-on-safari-iphone
  -webkit-text-size-adjust: 100%;
}

body *,
body *::before,
body *::after {
  box-sizing: inherit;
}

html {
  line-height: 1.15;
}

h2 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
}

h3 {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0 0 16px;
}

h4 {
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 1;
}

p {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin: 0 0 16px;
}

button {
  cursor: pointer;
  font-family: var(--font-inter);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
