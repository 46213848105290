@use "@/styles/constants/devices.scss" as *;

.navIconsContainer {
  display: flex;
  padding: 4px 16px 0 0;
  margin-left: 0;

  flex: 1 1 0;
  border-right: 1px solid var(--header-moreMenuIconsBorder);

  @media only screen and (min-width: $smallMobile) {
    padding: 4px 16px 0 40px;
  }

  @media only screen and (min-width: $tablet) {
    border-right: none;
    border-left: 1px solid var(--header-moreMenuIconsBorder);
    margin-left: 40px;
  }
}

.navIconLink {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--header-moreMenuIconsBackground);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;

  @media only screen and (min-width: $smallMobile) {
    margin-right: 16px;
  }

  @media only screen and (min-width: $tablet) {
    width: 40px;
    height: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.navIconButton {
  font-size: 0;
  background: var(--header-moreMenuIconsBackground);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  @media only screen and (min-width: $tablet) {
    width: 40px;
    height: 40px;
  }
}

.navIconNotificationContainer {
  position: relative;
}

.navIconNotification {
  background-color: var(--notifications-backgroundColor);
  color: var(--notifications-fontColor);
  font-size: 0.625rem;
  line-height: 0.1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -4px;
  top: -4px;
  @media only screen and (min-width: $tablet) {
    right: -3px;
    top: -3px;
  }
}
