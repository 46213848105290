@use "../../../styles/devices.scss" as *;

.container {
  display: flex;
  gap: 4px;

  & svg {
    height: 12px;
    width: 12px;

    @media screen and (min-width: $tablet) {
      height: 14px;
      width: 14px;
    }
  }
}
