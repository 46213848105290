@use "@/styles/constants/devices.scss" as *;

.sportsBlogFooterTag {
  background: var(--footer-backgroundColor);
  line-height: 2;
  hr {
    background-color: var(--accent);
    border: none;
    height: 10px;
    width: 100%;
    margin: 10px 0 0;
  }
}

.timeToThinkContainer {
  background-color: var(--timeToThink);
  text-align: center;
  padding: 20px 0;
}

.footerContainer {
  padding-top: 16px;
  display: grid;
  grid-template-columns: minmax(0px, 1fr) minmax(0px, 1020px) minmax(0px, 1fr);
  color: var(--footer-fontColor);
}

.footerContentContainer {
  font-size: 0.8125rem;
  line-height: 1.5;
  padding: 0 10px;
  margin-top: 1rem;
  display: block;
  margin-bottom: 32px;

  @media only screen and (min-width: $tablet) {
    display: grid;
    grid-auto-columns: minmax(0px, 1fr);
    grid-auto-flow: column;
    gap: 20px;
    margin-bottom: 0;
  }
}

.footerH4 {
  font-size: 0.8125rem;
  font-weight: 700;
  margin: 0;
}

.footerContactLink {
  text-decoration: none;
  color: var(--footer-fontColor);
}

.footerParagraph {
  margin: 1rem 0;
}
