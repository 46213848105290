@use "../../../styles/devices.scss" as *;

.statsTip {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--statsTipster-secondaryBorderColor);
  padding: 8px 16px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.competitionNameContainer {
  margin: 0 0 16px;
}

.competitionName {
  font-size: 0.8125rem;
  font-weight: 500;
  margin: 0;
  color: var(--statsTipster-subText);
  text-decoration: underline;

  @media screen and (max-width: $laptop) {
    font-size: 0.625rem;
  }
}

.matchInformationContainer {
  display: flex;
  place-content: center space-between;
  align-items: center;
}

.teamContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0%;
}

.team {
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0;
  padding: 0 0 8px;
  text-transform: capitalize;
  display: flex;
  color: var(--defaultText);

  @media screen and (max-width: $laptop) {
    font-size: 0.75rem;
  }
}

.teamBadge {
  width: 20px;
  margin: 0 16px 0 0;
}

.time {
  font-size: 0.8125rem;
  margin-left: auto;
  margin-right: 1rem;
  color: var(--statsTipster-subText);

  @media screen and (max-width: $laptop) {
    font-size: 0.625rem;
  }
}

.singleTeamForm {
  margin-right: 94px;

  @media screen and (max-width: $laptop) {
    margin-right: 86px;
  }
}

.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 24px;

  @media screen and (max-width: $laptop) {
    margin-right: 16px;
  }
}

.statsButtonContainer {
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex: 0.05 1 0%;
  padding: 0 8px 8px 0;
}

.statsButton {
  display: flex;
  width: 100%;
  padding: 12px 16px 10px;
  align-items: center;
  background: var(--statsTipster-statsButtonBackground);
  color: var(--statsTipster-statsButtonColor);
  border-radius: 8px;
  border: 1px solid var(--statsTipster-borderColor);

  .formIconContainer {
    margin-right: 7px;
  }

  .formIcon {
    margin-right: 7px;
    fill: var(--statsTipster-statsIconColor);
  }

  &[aria-pressed="true"] {
    background: var(--statsTipster-activeStatsButtonBackground);
    color: var(--statsTipster-activeStatsButtonColor);
    border-color: var(--statsTipster-activeStatsBorderColor);

    .formIcon {
      fill: var(--statsTipster-activeStatsIconColor);
    }
  }

  @media screen and (max-width: $laptop) {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    padding: 0;
    justify-content: center;

    & > span {
      display: none;
    }

    & > svg > g > g {
      margin: 0;
    }

    .formIconContainer {
      margin: 0;
    }
  }
}

.reasonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 8px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--statsTipster-reasonBorderColor);
  background: var(--statsTipster-reasonBackgroundColor);
  place-content: center space-between;
  align-items: center;
}

.market {
  font-size: 0.8125rem;
  font-weight: 700;
  color: var(--statsTipster-reasonMarketColor);
  margin: 0;
  text-align: left;
  width: 100%;
}

.reason {
  display: flex;
  width: 100%;
  color: var(--defaultText);

  & > article {
    padding: 0 !important;

    & > p {
      margin-top: 4px;
    }
  }
}
.statsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  place-content: center space-between;
  align-items: center;
}

.fpCompetitionLink {
  color: var(--statsTipster-subText);
  text-decoration: underline;
}
