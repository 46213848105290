@use '../../../styles/devices.scss' as *;

.accordionButton {
  background-color: var(--contentBackground);
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  font-weight: bold;
  color: var(--defaultText);
}

.accordionQuestionContainer {
  h3 {
    font-size: 0.95rem;
    margin: 0;
    text-align: left;
  }
}
