@use "../../../styles/devices.scss" as *;

.container {
  width: 100%;
  height: auto;
  background-color: var(--contentBackground);
  color: var(--defaultText);
  border-radius: 8px;
  padding: 16px 16px 0 16px;

  &:last-of-type {
    padding: 16px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: var(--bulletpointOfferCard-offerBackgroundColor);
  padding: 16px 16px 0;
  border-radius: 8px 8px 0 0;

  @media screen and (min-width: 770px) {
    gap: 0;
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 16px;
  }
}

.contentHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
  gap: 12px;
  font-family: var(--font-montserrat), sans-serif;

  @media screen and (min-width: $tablet) {
    gap: 24px;
    align-items: flex-start;
    order: 1;
  }
}

.title {
  font-size: 14px;
  font-weight: 700;

  @media screen and (min-width: $tablet) {
    font-size: 20px;
  }
}

.ctaAndScoreContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: $tablet) {
    width: auto;
    order: 3;
  }
}

.brand {
  border-radius: 4px;
  height: auto;
  width: 46px;

  @media screen and (min-width: $tablet) {
    height: auto;
    width: 56px;
  }
}

.scoreContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @media screen and (min-width: $tablet) {
    justify-content: space-between;
  }
}

.scoreDisplay {
  font-size: 10px;
  font-family: var(--font-inter), sans-serif;
  font-weight: 600;

  @media screen and (min-width: $tablet) {
    font-size: 13px;
  }
}

.cta {
  width: 100%;
  background-color: var(--bulletpointOfferCard-buttonBackgroundColor);
  border: none;
  height: 40px;
  border-radius: 4px;
  font-family: var(--font-montserrat), sans-serif;
  font-weight: bold;
  font-size: 10px;
  color: var(--bulletpointOfferCard-buttonFontColor);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  text-align: center;

  & svg {
    min-height: 14px;
    min-width: 14px;
  }

  @media screen and (min-width: $tablet) {
    padding: 8px;
    height: 48px;
    min-width: 144px;
    font-size: 14.4px;

    & svg {
      min-height: 16px;
      min-width: 16px;
    }
  }
}

.features {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  @media screen and (min-width: $tablet) {
    max-width: 252px;
    order: 2;
    gap: 12px;
  }

  & li {
    height: auto;
    width: auto;
    border-top: 1px solid var(--background);
    margin: 0 -16px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-family: var(--font-inter), sans-serif;
    font-weight: 600;
    font-size: 12px;

    & svg {
      min-height: 12px;
      min-width: 12px;
    }

    @media screen and (min-width: $tablet) {
      border-top: none;
      width: auto;
      font-size: 13px;
      align-items: center;
      height: auto;
      margin: 0;
      padding: 0;

      & svg {
        min-height: 16px;
        min-width: 16px;
      }
    }
  }

  &--hide {
    display: none;
    padding: 0 0 16px;
  }
}

.tAndCs {
  height: auto;
  background: var(--bulletpointOfferCard-offerBackgroundColor);
  color: var(--bulletpointOfferCard-tAndCsTextColor);
  border-top: 1px solid var(--background);
  margin: 0;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-inter), sans-serif;
  font-weight: 500;
  border-radius: 0 0 8px 8px;

  & p {
    font-size: 10px;
    margin: 0;
  }
}

.showFeatures {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  padding: 16px 0 0;
  border: 0;
  background: none;
  color: var(--defaultText);

  @media screen and (min-width: $tablet) {
    display: none;
  }

  &--show {
    padding: 16px 0;
  }
}
