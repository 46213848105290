.desktopMenuContainer {
  display: flex;
  flex: 1;
}

.desktopMenuUl {
  list-style: none;
  padding: 0;
  margin: 0.4rem 0 0 2rem;
  height: 2.1rem;
  display: flex;
  flex-flow: wrap;
  overflow: hidden;
  width: 100%;
}

.desktopMenuLi {
  text-align: center;
  font-size: 1rem;
  border-bottom: none;
  font-family: var(--font-montserrat);
  font-weight: 800;
  text-transform: uppercase;
}

.desktopMenuLink {
  text-decoration: none;
  display: block;
  padding: 0.5rem 0;
  margin-right: 2rem;
  color: var(--header-fontColor);

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &.active {
    padding-bottom: 4px;
    color: var(--header-activeSecondaryColor);
    border-bottom: 2px solid var(--header-activeSecondaryColor);
  }
}

.desktopMenuMoreButton {
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-family: var(--font-montserrat);
  font-weight: 800;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: var(--header-fontColor);

  &[aria-pressed="true"] {
    svg {
      transform: rotate(180deg);
    }
  }
}

.desktopMenuMoreSpan {
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
}

.desktopMenuMorePopupContainer {
  position: absolute;
  top: 72px;
  z-index: 1;
}

.desktopMenuMorePopup {
  width: 352px;
  background: var(--header-moreMenuBackground);
  font-family: var(--font-montserrat);
  font-weight: 800;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 0 24px;
  box-shadow: 0 0 8px 0 var(--header-moreMenuBoxShadow);
}
