.sportsBlogFooterNavContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  text-align: center;
  background-color: var(--footer-navBackgroundColor);
  margin-top: 1rem;
  padding: 1rem;
  color: var(--footer-fontColor);
}

.sportsBlogFooterNavUl {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 1rem 0;
}

.sportsBlogFooterNavATag {
  font-weight: 700;
  padding: 0 7px;
  text-decoration: none;
  color: var(--footer-fontColor);
}
