@use "../../../styles/devices.scss" as *;

.editorCard {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  padding: 16px;

  &.removePadding {
    padding: 0 0 16px 0;
  }

  > * {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0 0 0 16px;
    margin: 0 0 16px;
  }

  ol {
    padding: 0 0 0 16px;
    margin: 0 0 16px;
  }
  iframe {
    max-width: 100%;
  }
  /* Added due to overwrite the twitter iframe defaults */
  iframe[id="twitter-widget"] {
    width: 100% !important;
    flex-grow: unset !important;
  }
  iframe[title="Youtube Video"] {
    max-width: 100% !important;
  }
  /* Target Sporcle iframe and invert the colour scheme if using dark mode */
  iframe[id^="spFrame"] {
    filter: var(--editor-sporcle);
  }
  blockquote {
    border-left: 4px solid var(--editor-blockquote-border);
    padding: 0 0 0 16px;
    margin: 0 0 16px;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  a {
    color: var(--editor-fontColour);
    width: -webkit-fill-available !important;

    /* Added breaking of text due to long hyperlink on cookie policy distorting the width of the page on mobile */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    @media only screen and (min-width: $tablet) {
      /* Remove breaking of text on desktop */
      overflow-wrap: initial;
      word-wrap: initial;
      word-break: initial;
    }
  }

  p {
    margin-bottom: 16px;
  }

  h2,
  h3,
  h4 {
    margin-bottom: 16px;
  }
}
