@use "@/styles/constants/devices.scss" as *;

.headerWrapper {
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: 1rem;
  z-index: 10;
}

.headerBackground {
  background-color: var(--header-backgroundColor);
}

.headerLogoContainer {
  position: relative;
  min-width: 96px;
  min-height: 96px;

  @media only screen and (min-width: $tablet) {
    padding-left: 24px;
    min-width: 112px;
    min-height: 112px;
  }
}

.headerContainer {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  overflow: hidden;

  @media only screen and (min-width: $tablet) {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
    height: 96px;

    nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &--ppc {
    @extend .headerContainer;

    justify-content: center;
  }
}
