@use "../../../styles/devices.scss" as *;

.mediaContainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}

.mediaLink {
  text-decoration: none;
  color: var(--default-text);
}

.mediaImage {
  width: 100%;
  height: auto;
  @media only screen and (min-width: $tablet) {
    border-radius: 8px;
  }
}
