@use "@/styles/constants/devices.scss" as *;

.secondaryNavContainer {
  flex-basis: 100%;
  height: 48px;
  background-color: var(--secondaryNav-background);
  color: var(--contentHeaderFontColor);
  text-align: center;

  @media only screen and (min-width: $tablet) {
    display: grid;
    grid-template-columns: minmax(0px, 1fr) minmax(0px, 1020px) minmax(0px, 1fr);
  }
}

.secondaryNav {
  position: relative;
}

.secondaryNavHeader {
  display: none;
}

.secondaryNavUl {
  display: flex;
  align-items: center;
  height: 3rem;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  overflow-x: auto;
  pointer-events: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--ppc {
    @extend .secondaryNavUl;

    @media only screen and (min-width: $tablet) {
      justify-content: center;
    }
  }
}

.secondaryNavLink {
  text-decoration: none;
  color: var(--contentHeaderFontColor);
  white-space: nowrap;
  font-weight: 800;
  text-align: center;
  line-height: 2rem;
  transition: all 0.3s ease-in-out;
  text-transform: none;
  padding: 0.5rem 1rem;
  background-color: var(--secondaryNav-desktopLinkBackground);
  font-family: var(--font-inter);
  font-size: 0.8125rem;
  border-radius: 25px;
  margin-right: 10px;

  &:hover {
    background-color: var(--secondaryNav-linkBackgroundColor);
    color: var(--secondaryNav-activeLinkColor);
    transition: all 0.3s ease-in-out;
  }

  &.active {
    background: var(--secondaryNav-linkBackgroundColor);
    color: var(--secondaryNav-activeLinkColor);
  }
}

.secondaryAngleButton {
  position: absolute;
  padding: 18px 15px 13px 15px;
  top: 0;
  background-color: var(--secondaryNav-background);
  border: none;

  svg {
    color: var(--contentHeaderFontColor);
    overflow: visible;
  }
}

.secondaryAngleButtonRight {
  right: 0;
}

.secondaryAngleButtonLeft {
  left: -1rem;
}
