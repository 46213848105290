.betslip {
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 101;
  background: var(--betslip-background);
  color: var(--betslip-defaultText);
  box-shadow: transparent -10px 0px 15px 0px;
  overflow-y: auto;
  transition: transform 0.3s ease 0s;
  transform: translateX(110%);
}

.betslip.isOpen {
  transform: translateX(0);
}

.betslip__header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--betslip-borderBottomColor);
}

.betslip__heading {
  order: 1;
  text-transform: capitalize;
  font-family: var(--font-inter);
  font-size: 0.825rem;
  font-weight: 500;
  padding-left: 1rem;
  margin: 0.75rem 0;
  line-height: 1;
}

.betslip__close {
  order: 0;
  font-size: 0;
  background-color: transparent;
  border: none;

  svg {
    font-size: 1rem;
    color: var(--betslip-defaultText);
  }
}

.betslip__clear {
  order: 2;
  background-color: transparent;
  border: none;
  color: var(--betslip-clearButtonFontColor);
}
